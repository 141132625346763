.footer{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    margin-bottom: 1vw;
    .footerLogo{
        width: 30vw;
    }
    .footerLinks{
        font-size: calc(5px + 0.52vw);
        a{
            color: black;
            text-decoration: none;
            &:hover{
                font-weight: bold;
            }
            &:active:before{
                color: #766037
            }
        }
    }
    .socialnetworks{
        display: flex;
        gap: 1vw;
        .socialnetworksBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 2.5vw;
            max-width: 2.5vw;
            min-height: 2.5vw;
            max-height: 2.5vw;
            img{
                min-width: 1.5vw;
                max-width: 1.5vw;
                min-height: 1.5vw;
                max-height: 1.5vw;
            }
        }
    }
    .footerCopyright{
        font-size: calc(5.33px + 0.555vw);
    }
}

@media screen and (max-width:767px){
    .footer{
        margin-top: 56px;
        .footerLogo{
            width: 315px;
            height: 47px;
            margin-top: 34px;
        }
        .footerLinks{
            margin-top: 23px;
            font-size: 15px;
        }
        .socialnetworks{
            gap: 16px;
            .socialnetworksBtn{
                min-width: 48px;
                max-width: 48px;
                min-height: 48px;
                max-height: 48px;
                img{
                    min-width: 25px;
                    max-width: 25px;
                    min-height: 25px;
                    max-height: 25px;
                }
            }
        }
        .footerCopyright{
            font-size: 16px;
            text-align: center;
        }
    } 
}