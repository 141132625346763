.btn-info {
    --bs-btn-color: white;
    --bs-btn-bg: rgba(5, 166, 194, 1);
    --bs-btn-border-color: rgba(5, 166, 194, 1);
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #037A8F;
    --bs-btn-hover-border-color: #037A8F;
    --bs-btn-focus-shadow-rgb: 11,172,204;
    --bs-btn-active-color: white;
    --bs-btn-active-bg: rgba(118, 96, 55, 1);
    --bs-btn-active-border-color: rgba(118, 96, 55, 1);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: white;
    --bs-btn-disabled-bg: rgba(5, 166, 194, 1);
    --bs-btn-disabled-border-color: rgba(5, 166, 194, 1);
    font-weight: bold;
    &:hover {
        color: white;
    }
}
