.aboutusCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 34vw;
    height: 17vw;
    background-color: #FBFAF8;
    border-radius: 5%;
    padding: 2vw;
    filter: drop-shadow(3px 4px 20px rgba(118, 96, 55, 0.1));
    h3{
        margin-top: calc(5px + 0.52vw);
        font-family: 'Roboto';
        font-weight: 600;
        font-size: calc(6.66px + 0.695vw);
        color: rgba(2, 94, 110, 1);
    }
    .aboutusCardIcon{
        width: 3vw;
    }
    p{
        margin-top: calc(5px + 0.52vw);
        font-family: 'Open sans';
        font-weight: 400;
        font-size: calc(5.33px + 0.555vw);
        color: rgba(47, 38, 22, 1)
    }
}
.aboutusCard1{
    position: relative;
    bottom: 2vw;
    left: 1vw;
}
.aboutusCard2{
    position: relative;
    top: 5vw;
    right: 1.5vw;
}

@media screen and (max-width:992px){
    .aboutusCard{
        h3{
            font-size: calc(6px + 0.625vw);
        }
        p{
            font-size: calc(4.33px + 0.5vw);
        }
    }
}

@media screen and (max-width:767px){
    .aboutusCard{
        width: 327px;
        height: 416px;
        gap: 2vw;
        text-align: start;
        .aboutusCardIcon{
            width: 54px;
            height: 54px;
        }
        h3{
            font-size: 18px;
        }
        p{
            font-size: 13px;
        }
    }
}