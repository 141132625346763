.nav-link {
  position: relative;
  display: inline-block;
  font-weight: bold;
  .active:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #766037;
    border-radius: 4px;
    scale: 1;
    transform-origin: left;
    transition: scale 0.25s;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #766037;
    border-radius: 4px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.25s;
  }
  &:hover::before {
    scale: 1;
  }
}

.navbar {
  --bs-border-width: 0px;
  --bs-navbar-toggler-focus-width: 0px;
  .navbarLinks {
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 12px;
    .navbarLinksRefs {
      color: #766037;
      text-decoration: none;
      font-family: "Roboto";
      font-weight: 500;
      padding-top: 8px;
      font-size: calc(14.66px + 0.382vw);
    }
  }
  .navbarLinksButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-weight: 700;
    font-size: calc(13.32px + 0.3475vw);
    width: calc(133.33px + 3.472vw);
    padding-top: 8px;
    padding-bottom: 8px;

    .active {
      background-color: #766037;
      border: 10px solid #766037;
      border-radius: 0.375rem;
    }
  }

  .navbarLinkButton {
    width: 200px;
    margin: -0.8rem;
    text-decoration: none;
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .nav-link {
    .active:before {
      width: 100%;
    }
    &:before {
      width: 100%;
    }
  }
  .navbar {
    .navbarLinks{
      align-items: center;
      .navbarLinksRefs{
        font-size: 18px;
      }
    }
    .navbarLinksButton {
      .active {
        border: 2px solid #766037;
      }
    }
  }
  .navbarLinkButton {
    padding: 8px 0;
  }
}

/* @media screen and (max-width:767px) {
    .navbar{
        height: 72px;
    }
} */

/* .navbarButton {
    color: white;
    font-weight: bold;
    &:hover {
        color: white;
    }
} */

/* CODE TO CHANGE NAVBAR ICON COLOR */
/* .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.198242H18V2.95233H0V0.198242ZM0 9.74148V7.05144H18V9.74148H0ZM0 16.5947V13.8406H18V16.5947H0Z' fill='%2305A6C2'/%3E%3C/svg%3E");
} */
