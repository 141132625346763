.heroVectorHome {
  height: 53vw;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='767' viewBox='0 0 1440 767' fill='none'%3E%3Cpath d='M702.39 707.433C417.006 782.317 115.22 768.135 0 751.683V0H1440V767C1313.04 715.942 987.774 632.548 702.39 707.433Z' fill='url(%23paint0_linear_292_3155)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_292_3155' x1='97.6356' y1='273.5' x2='1363.89' y2='273.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.341667' stop-color='%23BA9F6B' stop-opacity='0.93'/%3E%3Cstop offset='1' stop-color='%23766037' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  position: absolute;
  z-index: -1;
  margin-top: 1%;
  background-repeat: no-repeat;
}

.hero {
  display: flex;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='767' viewBox='0 0 1440 767' fill='none'%3E%3Cpath d='M702.39 707.433C417.006 782.317 115.22 768.135 0 751.683V0H1440V767C1313.04 715.942 987.774 632.548 702.39 707.433Z' fill='url(%23paint0_linear_292_3159)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_292_3159' x1='97.6356' y1='273.5' x2='1363.89' y2='273.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.341667' stop-color='%23766037' stop-opacity='0.95'/%3E%3Cstop offset='1' stop-color='%23766037' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"),
    url("../../../public/hero-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  .heroText {
    height: 53vw;
    margin-left: 10%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2%;
    h1 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: calc(20px + 2.0833vw);
    }
    p {
      font-size: calc(7.33px + 0.764vw);
      font-weight: 400;
      font-family: "Open sans";
    }
    .heroTextBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(13.32px + 0.348vw);
      width: calc(188.66px + 4.9133vw);
      height: calc(37.33px + 0.9733vw);
    }
  }
  .none {
    width: 200vw;
  }
}

.aboutus {
  color: rgba(118, 96, 55, 1);
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 10%;
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  h2 {
    font-size: calc(13.33px + 1.388vw);
    font-weight: 600;
    font-family: "Roboto";
  }
  .aboutusText {
    font-family: "Open sans";
    font-weight: 400;
    font-size: calc(7.33px + 0.764vw);
    color: rgba(47, 38, 22, 1);
  }
  .aboutusCards {
    display: flex;
    margin-top: 5vw;
    .aboutusDecoration {
      height: 7vw;
      width: 7vw;
      position: relative;
      /* bottom: 5vw; */
      z-index: 1;
    }
    .circle {
      position: absolute;
      left: 78vw;
      height: 13vw;
      width: 13vw;
      border-radius: 50%;
      background-color: rgba(181, 164, 144, 0.14);
      z-index: -1;
    }
  }
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
  background-color: #f5f5f5;
  padding: 5vw;
  h2 {
    font-weight: 600;
    font-family: "Roboto";
    font-size: calc(13.33px + 1.388vw);
    color: rgba(118, 96, 55, 1);
  }
  .servicesCards {
    display: flex;
    justify-content: space-around;
    gap: 2vw;
  }
}

.specialists {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3vw;
  margin-bottom: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  h2 {
    font-weight: 600;
    font-family: "Roboto";
    font-size: calc(13.33px + 1.388vw);
    color: rgba(118, 96, 55, 1);
  }
  .specialistsText {
    font-family: "Open sans";
    font-weight: 400;
    margin-bottom: 2vw;
    font-size: calc(7.33px + 0.764vw);
    color: rgba(47, 38, 22, 1);
  }
  .specialistsCards {
    margin-top: 2vw;
    display: flex;
    gap: 5vw;
  }
  .specialistsCardsMobile {
    display: none;
  }
}

.schedule {
  height: 53vw;
  background-image: url("../../../public/hero-img2.png");
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 20%;
  color: #f5f5f5;
  .scheduleText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    h1 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: calc(20px + 2.0833vw);
    }
    p {
      font-family: "Open sans";
      font-weight: 400;
      font-size: calc(7.33px + 0.764vw);
    }
    .scheduleTextSecondary {
      margin-top: 2vw;
    }
    .scheduleTextMobile {
      display: none;
    }
    .heroTextBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(13.32px + 0.348vw);
      width: calc(188.66px + 4.9133vw);
      height: calc(37.33px + 0.9733vw);
    }
  }
}

@media screen and (max-width: 1080px) {
  .scheduleTextDesktop {
    display: none;
  }
  .schedule > .scheduleText > .scheduleTextMobile {
    display: inline;
  }
}

@media screen and (max-width: 767px) {
  .hero {
    .heroText {
      text-align: center;
      margin-left: 0%;
      margin: 5%;
      align-items: center;
      height: 767px;
      h1 {
        font-size: 36px;
        justify-content: center;
      }
      p {
        font-size: 15px;
      }
      .heroTextBtn {
        width: 283px;
        font-size: 18px;
      }
    }
    .none {
      display: none;
    }
  }

  .aboutus {
    padding-top: 33px;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    h2 {
      font-size: 24px;
    }
    .aboutusText {
      font-size: 15px;
    }
    .aboutusCards {
      flex-direction: column;
      align-content: center;
      padding-bottom: 66px;
      .aboutusDecoration {
        display: none;
      }
      .circle {
        position: absolute;
        left: 35vw;
        height: 242px;
        width: 242px;
        top: 1460px;
        border-radius: 50%;
        background-color: rgba(181, 164, 144, 0.14);
        z-index: -1;
        overflow: hidden;
      }
    }
  }
  .services {
    padding-top: 58px;
    padding-bottom: 72.15px;
    gap: 21.58px;
    h2 {
      font-size: 24px;
    }
    .servicesCards {
      flex-direction: column;
      gap: 35.98px;
    }
  }
  .specialists {
    padding-top: 81px;
    margin-left: 10%;
    margin-right: 10%;
    h2 {
      font-size: 24px;
      text-align: center;
    }
    .specialistsText {
      font-size: 15px;
      text-align: center;
    }
    .specialistsCards {
      display: none;
    }
    .specialistsCardsMobile {
      display: inline-block;
    }
  }
  .schedule {
    height: 739px;
    background-image: url("../../../public/hero-img2.png");
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 20%;
    color: #f5f5f5;
    .scheduleText {
      
      p{
        width: 110%;
        font-size: 15px;
        text-align: center;
      }
      .scheduleTextMobile {
        width: 130%;
        font-size: 36px;
        display: inline-block;
        text-align: center;
      }
      .scheduleTextBtn {
        font-size: 18px;
        width: 245px;
        height: 48px;
        padding-top: 8px;
      }
    }
  }
}

/* .custom-shape-divider-bottom-1682822924 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    svg {
        position: relative;
        display: block;
        width: calc(150% + 1.3px);
        height: 100px;
        .shape-fill {
            fill: #FFFFFF;
        }
    }
}

.custom-shape-divider-bottom-1682822924 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 100px;
}

.custom-shape-divider-bottom-1682822924 .shape-fill {
    fill: #FFFFFF;
} */
