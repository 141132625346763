.card{
    --bs-card-border-radius: 15px;
    --bs-card-inner-border-radius: 15px
}
.card3{
    height: 36vw;
    width: 23vw;
    border-width: 0;
    background: #FBFAF8;
    box-shadow: 3px 4px 15px rgba(5, 166, 194, 0.1);
    .card3Body{
        font-size: calc(5.33px + 0.555vw);
        margin: 1vw;
        display: flex;
        flex-direction: column;
        gap: 0.7vw;
        .card3BodyTitle{
            font-family: 'Roboto';
            font-weight: 600;
            font-size: calc(8.33px + 0.868vw);
            color: rgba(118, 96, 55, 1);
        }
        .card3text{
            font-family: 'Open sans';
        font-weight: 400;
        }
    }
    .card3BodyButton{
        font-size: calc(13.32px + 0.3475vw);
        width: calc(44px + 4.5832vw);
        height: calc(18.665px + 1.9466vw);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.42vw;
        padding-bottom: 0.42vw;
        margin-left: 2vw;
        margin-top: 138%;
    }
}

@media screen and (max-width:992px){
    .card3{
        .card3Body{
            font-size: calc(4.66px + 0.486vw);
            .card3BodyTitle{
                font-size: calc(6px + 0.625vw);
            }
        }
        .card3BodyButton{
            font-size: calc(5.33px + 0.555vw);
        }
    }
}

@media screen and (max-width:767px){
    .card3{
        height: 492px;
        width: 260px;
        .card3Body{
            font-size: 14px;
            .card3BodyTitle{
                font-size: 18px;
            }
        }
        .card3BodyButton{
            width: 100px;
            height: 48px;
            font-size: 16px;
            padding: 9px;
            margin-top: 412px;
        }
    }
}